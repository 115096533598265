import './welcome.css';

import logo from '../assets/wer.png';
import feditextLogo from '../assets/clients/feditext.svg';
import tuskyLogo from '../assets/clients/tusky.svg';

import Link from '../components/link';
import states from '../utils/states';
import useTitle from '../utils/useTitle';

function Welcome() {
  useTitle(null, ['/', '/welcome']);
  return (
    <main id="welcome">
      <div class="hero-container">
        <h1>
          <Link to="/login">
            <img
                src={logo}
                alt=""
                width="200"
                height="200"
                style={{
                aspectRatio: '1/1',
                marginBlockEnd: -16,
                }}
            />
          </Link>
        </h1>
        <p>
          <big>
            <b>
              <Link to="/login" class="button">
                Log in
              </Link>
            </b>
          </big>
        </p>
        <p class="desc">We R.social. We are all everyone, here.</p>
        <p class="clients">
          <p>
            You can also use <a href="https://joinmastodon.org/apps">any</a> Mastodon-compatible app to log in:
          </p>
          <ul class="grid">
            <li>
                <h4>Tusky</h4>
                <img src={tuskyLogo} alt="Tusky" />
                <Link to="https://tusky.app/">Get Tusky (Android)</Link>
                <p>
                  Tusky is a lightweight android client for Mastodon with a focus on speed.
                </p>
            </li>
            <li>
                <h4>Feditext</h4>
                <img src={feditextLogo} alt="Feditext" />
                <Link to="https://fedi.software/@Feditext">Get Feditext (iOS)</Link>
                <p>
                  Feditext is a beautiful iOS client to access the fediverse.
                </p>
            </li>
          </ul>
        </p>
      </div>
      <div id="why-container">
        <div class="sections">
          <section>
            <h4>We R.social</h4>
            <p>
              We R.social is a Fediverse instance that is geared towards bringing
              communities of geographical, socialogical, cultural backgrounds
              together in a decentralized microblogging social network.
            </p>
            <p>
              We are all here. We are all one. We are all connected.
            </p>
            <p>
              This instance allows sharing of a thematic blogging account,
              shared amongst a group of people.
              Each person can use the account to make posts of interest to the
              group, and then the account passes to the next person in the
              group.
            </p>
            <p>
              Through this, we can all be connected, and we can all learn from
              each other.
            </p>
          </section>
          <section>
            <h4>Your community here…</h4>
            <p>
              If you'd like to start your own community here, please contact us
              on matrix at <a
                href="https://matrix.to/#/#WeR.social:chat.blahaj.zone"
              >@WeR.social:chat.blahaj.zone</a
              >, or email us at <a
                href="mailto:admin@wer.social"
              >admin@WeR.social</a>
            </p>
            <p>
              For more information, including our rules and terms of use,
              please visit our <a
                href="https://wer.social/about"
              >about page</a>.
            </p>
          </section>
        </div>
      </div>
    </main>
  );
}

export default Welcome;
